// features:
// slide left/right width buttons/arrows, or swipe
// optional: scroll indicator
// optional: slider header navigation
// optional: expandables
// optional: infinite loop
/* Based on https://github.com/jabes/snap-touch */

if(typeof a1FragmentsSlider === 'undefined'){
    const SesoSlider = class {

        constructor(selector) {
            this.el = {};
            this.el.container = selector instanceof HTMLElement
                ? selector
                : (
                    document.getElementById(selector) ||
                    document.querySelector(selector)
                );

            this.el.slider = this.el.container ? this.el.container
                : undefined;
            this.el.animator = this.el.container ? this.el.container.querySelector('.animator')
                : undefined;
            this.el.animator = this.el.container ? this.el.container.querySelector('.animator')
                : undefined;
            this.el.items = this.el.container ? this.el.container.querySelector('.items')
                : undefined;
            this.el.anchors = this.el.animator ? this.el.animator.querySelectorAll('a[href]')
                : [];
            this.el.buttonNext = this.el.container ? this.el.container.querySelector('button.next')
                : undefined;
            this.el.buttonPrev = this.el.container ? this.el.container.querySelector('button.prev')
                : undefined;
            this.el.scrollIndicator = this.el.container.querySelector('.scroll-indicator .indicator')
                ? this.el.container.querySelector('.scroll-indicator .indicator')
                : undefined;

            // slider header
            this.el.sliderHeaderWrapper = this.el.container.closest('a1-fragment').querySelector('.slider-header')
                ? this.el.container.closest('a1-fragment').querySelector('.slider-header')
                : undefined;
            this.el.sliderHeaderAnimator = this.el.sliderHeaderWrapper
                ? this.el.sliderHeaderWrapper.querySelector('.animator')
                : undefined;
            this.el.sliderHeaderItems = this.el.sliderHeaderWrapper
                ? this.el.sliderHeaderWrapper.querySelectorAll('.slider-header-controller')
                : undefined;
            this.el.sliderHeaderIndicator = this.el.sliderHeaderWrapper
                ? this.el.sliderHeaderWrapper.querySelector('.indicator')
                : undefined;
            this.el.sliderHeaderBtnNext = this.el.sliderHeaderWrapper
                ? this.el.sliderHeaderWrapper.querySelector('.slider-header-button.next')
                : undefined;
            this.el.sliderHeaderBtnPrev = this.el.sliderHeaderWrapper
                ? this.el.sliderHeaderWrapper.querySelector('.slider-header-button.prev')
                : undefined;

            // expandables
            this.el.expandableWrapper = this.el.container.querySelector('.item .expandable')
                ? this.el.container.querySelector('.item .expandable')
                : undefined;
            this.el.expandables = this.el.container.querySelectorAll('.item .expandable-item')
                ? this.el.container.querySelectorAll('.item .expandable-item')
                : [];
            this.el.expandablesController = this.el.container.querySelectorAll('.item .expandable-controller')
                ? this.el.container.querySelectorAll('.item .expandable-controller')
                : [];
            this.el.expandablesContent = this.el.container.querySelectorAll('.item .expandable-content')
                ? this.el.container.querySelectorAll('.item .expandable-content')
                : [];

            // number of slides before clones
            this.el.slidesBeforeClonesTotal = this.el.animator.querySelectorAll('.item').length;

            this.events = {};

            this.params = {
                sliderVisibleWidth: undefined,
                slideWidth: undefined,
                slideTotal: undefined,
                position: 0,
                positionMax: undefined,
                trackingStartX: undefined,
                trackingEndX: undefined,
                trackingDirection: undefined,
                translateX: 0,
                posX: 0,
                lastPosX: 0,
                amplitude: undefined,
                velocity: undefined,
                firstTimestamp: undefined,
                lastTimestamp: undefined,
                ticker: undefined,
                isClick: undefined,
                hasMoved: undefined,
                isEasing: undefined,
                isInitialized: undefined,
                dataInitialSlide: 'data-initial-slide',
                dataInfiniteSlider: 'data-infinite',
                dataAutoplay: 'data-autoplay',
                dataDelay: 'data-delay',
                initialSlideSet: false,
                noSliderClass: 'no-slider',
                sliderInitializedClass: 'slider-initialized',
                dataSlideNumber: 'data-slide',
                visibleSlideIds: [],
                hasHeader: false,
                ticking: false,
                sliderHeaderIsMoving: false,
                sliderHeaderMovingDirection: '',
                sliderHeaderMoveDistance: 180,
                sliderHeaderAnimationDuration: 200,
                classNoTransition: 'no-transition-wrapper',
                classActive: 'active',
                hasExpandables: false,
                classExpandableItem: 'expandable-item',
                classExpandableContent: 'expandable-content-inner',
                classExpandableOpen: 'is-open',
                dataAttrExpandableId: 'data-expandable-id',
                dataAttrExpandableHeight: 'data-expandable-height',
                expandableIds: [],
                cloneItem: null,
                cloneItemLast: null
            };

            if(this.el.slider.getAttribute(this.params.dataInfiniteSlider) === 'true') {
                //create clones before set this.el.slides
                this.createClones();
            }

            this.el.slides = this.el.animator ? this.el.animator.querySelectorAll('.item')
                : [];

            if (this.el.container && this.el.slides.length > 0) {
                this.removeLazyLoadingImg();
                this.initSlider();
                // remove class after initial-slide set
                this.el.animator.classList.remove(this.params.classNoTransition);
                // autoplay
                this.autoplaySlides();
            }
        }

        // remove loading lazy for images inside slides with source tag
        removeLazyLoadingImg() {
            for (let i = 0; i < this.el.slides.length; i++) {
                if(this.el.slides[i].getElementsByTagName('picture').length > 0) {
                    let picture = this.el.slides[i].querySelector('picture');
                    picture.querySelector('img').setAttribute('loading', 'eager');
                }
            }
        }

        createClones() {
            // create clone last
            let allslides = this.el.animator.querySelectorAll('.item').length;
            let lastSlideItem = this.el.animator.querySelectorAll('.item')[allslides - 1];
            let cloneItemLast = lastSlideItem.cloneNode(true);
            cloneItemLast.classList.add('cloned');
            cloneItemLast.classList.add('last-item');
            this.el.animator.querySelector('.items').insertBefore(cloneItemLast, this.el.animator.querySelectorAll('.item')[0]);

            // create clone first
            let fistSlideItem = this.el.animator.querySelectorAll('.item')[1];
            let cloneItem = fistSlideItem.cloneNode(true);
            cloneItem.classList.add('cloned');
            cloneItem.classList.add('first-item');
            this.el.animator.querySelector('.items').appendChild(cloneItem);

            this.params.cloneItem = cloneItem ? cloneItem : null;
            this.params.cloneItemLast = cloneItemLast ? cloneItemLast : null;
        }

        initSlider () {
                // check if there is an optional slider header
                if(typeof this.el.sliderHeaderWrapper !== 'undefined') {
                    this.params.hasHeader = true;
                    this.initSliderHeader();
                }

                // check if there are optinal expandables
                if(typeof this.el.expandableWrapper !== 'undefined') {
                    this.params.hasExpandables = true;
                    this.initExpandables();
                }

                // init
                // set numberation to items
                for (let i = 0; i < this.el.slides.length; i++) {
                    this.el.slides[i].setAttribute(this.params.dataSlideNumber, i + 1);
                }

                this.positionScrollIndicator();
                this.bindEvents();
                // reset on init
                this.destroy();
                this.resize();
        }

        // position scrollIndicator according to first slide height and legal text height for tease primary full width
        positionScrollIndicator() {
            let legalText = [];

            for (let i = 0; i < this.el.slides.length; i++) {
                let tempLegal = this.el.slides[i].querySelector('.legal-text');
                if(tempLegal !== null && typeof(tempLegal) !== 'undefined') {
                    legalText.push(tempLegal);
                }

            }

            if((legalText.length > 0 && legalText !== null) && typeof(this.el.scrollIndicator) !== 'undefined') {
                let positionIndicator = legalText[0].offsetHeight + 16;
                this.el.scrollIndicator.parentElement.style.bottom = positionIndicator + 'px';
            }
        }


        preventDefault(event) {
            event.preventDefault();
            return false;
        }

        dispatchEvent(typeArg, customEventInit = { bubbles: false, cancelable: false, detail: null }) {
            const event = new window.CustomEvent(typeArg, customEventInit);
            this.el.container.dispatchEvent(event);
            return event;
        }

        bindEvents() {
            this.on(this.el.animator, 'dragstart', this.preventDefault);
            this.on(this.el.animator, 'touchstart', this.touchStart);
            this.on(this.el.animator, 'mousedown', this.touchStart);
            this.on(this.el.animator, 'touchend', this.touchEnd);
            //this.on(this.el.animator, 'mouseup', this.stopTracking);

            // Add event listeners to buttons (next/prev)
            this.on(this.el.buttonNext, 'click', this.goToNext);
            this.on(this.el.buttonPrev, 'click', this.goToPrev);

            // Add event listener for keydown (arrow left/right moves)
            // this.on(this.el.container.closest('.a1-slider-product'), 'keydown', this.keyboardMove);

            var that = this;
            for (var i = 0; i < this.el.anchors.length; i++) {
                this.on(this.el.anchors.item(i), 'click', function (event) {
                    that.preventDefault(event);
                    if (that.params.isClick) {
                        that.delayLocationChange(event._target.href);
                    }
                });
            }

            this.on(window, 'resize', this.resize);
        }

        goToNext(event) {
            event.preventDefault();
            if(this.el.items.classList.contains('t-end')) {
                this.setPosition(this.params.position + 1);
                this.el.items.classList.remove('t-end');
            }
        }

        goToPrev(event) {
            event.preventDefault();
            if(this.el.items.classList.contains('t-end')) {
                this.setPosition(this.params.position - 1);
                this.el.items.classList.remove('t-end');
            }
        }

        on(target, type, callback) {
            const key = target._key || Math.random().toString(36).substring(7);
            target._key = key;
            if (!this.events[key]) {
                this.events[key] = {};
            }
            if (!this.events[key][type]) {
                this.events[key][type] = {
                    target: target,
                    listeners: [],
                };
            }
            const _listener = (event) => {
                event._key = key;
                event._target = target;
                callback.call(this, event);
            };
            this.events[key][type].listeners.push(_listener);
            target.addEventListener(type, _listener);
        }

        off(target, type = null) {
            const key = target._key;
            const _removeByType = (key, type) => {
                const target = this.events[key][type].target;
                const listeners = this.events[key][type].listeners;
                for (let i = 0; i < listeners.length; i++) {
                    target.removeEventListener(type, listeners[i]);
                    this.events[key][type].listeners.splice(i, 1);
                }
                if (this.events[key][type].listeners.length === 0) {
                    delete this.events[key][type];
                }
                if (Object.keys(this.events[key]).length === 0) {
                    delete this.events[key];
                }
            };

            if (this.events[key]) {
                if (type) {
                    _removeByType(key, type);
                } else {
                    const events = this.events[key];
                    for (let type in events) {
                        if (events[type]) {
                            _removeByType(key, type);
                        }
                    }
                }
            }
        }

        startTracking(event) {
            if (this.params.ticker) {
                this.stopTracking();
            }
            this.params.ticker = window.setInterval(this.track.bind(this), 100);

            this.params.trackingStartX = this.eventCoords(event, 'startTracking').x;
            this.params.translateX = this.translateValues(this.el.animator).x;

            this.on(document, 'touchmove', this.touchMove);
            this.on(document, 'mousemove', this.touchMove);
            this.on(document, 'touchend', this.touchEnd);
            this.on(document, 'mouseup', this.touchEnd);
            this.dispatchEvent('SesoSlider.trackingStart');
        }

        stopTracking() {
            window.clearInterval(this.params.ticker);
            delete this.params.ticker;

            this.off(document);
            this.dispatchEvent('SesoSlider.trackingEnd');
        }

        track() {
            var now = Date.now();
            var timeElapsed = now - this.params.lastTimestamp;
            var delta = this.params.posX - this.params.lastPosX;
            var v = 1000 * delta / (1 + timeElapsed);
            this.params.velocity = 0.8 * (v + 0.2) * this.params.velocity;
            this.dispatchEvent('SesoSlider.tracking', {
                bubbles: false,
                cancelable: false,
                detail: {
                    now: now,
                    delta: delta,
                    velocity: this.params.velocity,
                    posX: this.params.posX,
                    lastPosX: this.params.lastPosX,
                    lastTimestamp: this.params.lastTimestamp
                }
            });
            this.params.lastPosX = this.params.posX;
            this.params.lastTimestamp = now;
        }

        touchStart(event) {
            if(this.el.items.classList.contains('t-end')){
                this.params.velocity = 0;
                this.params.amplitude = 0;
                this.params.posX = this.params.posX || 0;
                this.params.lastPosX = this.params.posX;
                this.params.firstTimestamp = Date.now();
                this.params.lastTimestamp = this.params.firstTimestamp;
                this.params.trackingStartX = this.eventCoords(event, 'touchStart').x;
                this.params.isEasing = false;
                this.params.isClick = false;
                this.params.hasMoved = false;
                this.startTracking(event);
            }
        }

        touchMove(event) {
            if (this.params.isInitialized) {
                const delta = this.eventCoords(event, 'toucheMove').x - this.params.trackingStartX;
                this.params.trackingDirection = (delta >= 0) ? 'right' : 'left';

                // move left/right
                if (delta > 20 || delta < -20) {
                    // check left and right border
                    let moveTo = delta + this.params.translateX;

                    if(this.el.slider.getAttribute(this.params.dataInfiniteSlider) !== 'true') {
                        if (moveTo > 0) {
                            moveTo = 0;
                        }
                    }

                    const rightBorder = (this.params.trackingEndX - this.params.slideWidth) * -1;

                    if(this.el.slider.getAttribute(this.params.dataInfiniteSlider) !== 'true') {
                        if (moveTo < rightBorder) {
                            moveTo = rightBorder;
                        }
                    }

                    // move slider left/right
                    this.el.animator.style.transform = 'translateX(' + moveTo + 'px)';

                    if(moveTo != rightBorder) {
                        this.el.items.classList.remove('t-end');
                    }

                    if(moveTo == 0) {
                        this.el.items.classList.add('t-end');
                    }
                }
            }
        }

        touchEnd() {
            this.stopTracking();
            this.params.amplitude = this.params.targetX - this.params.posX;
            this.params.lastTimestamp = Date.now();

            if (this.params.isInitialized) {
                this.requestAnimation(this.snapPosition);
            }
            // IE and FF fire click events on drag/swipe, so we need to decide if click behaviour should be honoured
            var timeElapsed = this.params.lastTimestamp - this.params.firstTimestamp;
            this.params.isClick = !this.params.hasMoved && timeElapsed < 300;
        }

        snapPosition() {
            var timeConstant = 325;
            var timeElapsed = Date.now() - this.params.lastTimestamp;
            var delta = -this.params.amplitude * Math.exp(-timeElapsed / timeConstant);
            var xMin = this.params.slideWidth * -(this.params.slideTotal - 1);
            var xMax = 0;
            this.params.isEasing = this.params.posX > xMin && this.params.posX < xMax && (delta > 20 || delta < -20);

            const snap = this.params.slideWidth;
            const myX = this.translateValues(this.el.animator).x * -1;
            let position = 0;
            if (this.params.trackingDirection == 'left') {
                position = (Math.ceil(myX / snap) * snap) / this.params.slideWidth;
            } else {
                position = (Math.floor(myX / snap) * snap) / this.params.slideWidth;
            }
            this.setPosition(position, 'snapPosition');
        }

        requestAnimation(callback) {
            callback = callback.bind(this);
            if (window.requestAnimationFrame) {
                window.requestAnimationFrame(callback);
            } else {
                window.setTimeout(callback, 10);
            }
        }

        delayLocationChange(href) {
            var setLocation = function setLocation(href) {
                if (this.params.isEasing) {
                    this.delayLocationChange(href);
                } else {
                    window.location.href = href;
                }
            };
            window.setTimeout(setLocation.bind(this, href), 50);
        }

        translateValues(element) {
            const style = window.getComputedStyle(element);
            const matrix = style['transform'] || style.webkitTransform || style.mozTransform

            // No transform property. Simply return 0 values.
            if (matrix === 'none' || typeof matrix === 'undefined') {
                return {
                    x: 0,
                    y: 0,
                    z: 0
                }
            }

            // Can either be 2d or 3d transform
            const matrixType = matrix.includes('3d') ? '3d' : '2d'
            const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')

            // 2d matrices have 6 values
            // Last 2 values are X and Y.
            // 2d matrices does not have Z value.
            if (matrixType === '2d') {
                return {
                    x: parseInt(matrixValues[4]),
                    y: parseInt(matrixValues[5]),
                    z: 0
                }
            }

            // 3d matrices have 16 values
            // The 13th, 14th, and 15th values are X, Y, and Z
            if (matrixType === '3d') {
                return {
                    x: parseInt(matrixValues[12]),
                    y: parseInt(matrixValues[13]),
                    z: parseInt(matrixValues[14])
                }
            }

            return {
                x: 0,
                y: 0,
                z: 0
            }
        }

        eventCoords(event) {
            const touchEvents = ['touchmove', 'touchstart', 'touchend'];
            const isTouchEvent = touchEvents.indexOf(event.type) > -1;
            if (isTouchEvent) {
                let touch = event.targetTouches[0] || event.changedTouches[0];
                return {
                    x: touch.clientX,
                    y: touch.clientY,
                };
            } else {
                return {
                    x: event.clientX,
                    y: event.clientY,
                };
            }
        }

        setPositionInfinity(position) {
            this.el.animator.classList.remove('no-transition-first');
            this.params.cloneItem.classList.remove('loaded');
            this.params.cloneItem.style.left = 'auto';

            this.params.cloneItemLast.classList.remove('loaded');
            this.params.cloneItemLast.style.left = 'auto';

            this.params.position = (position < 0) ? 0 : position;

            const xTarget = this.params.position * this.params.slideWidth * -1;
            this.el.animator.style.transform = 'translateX(' + xTarget + 'px)';

            this.el.animator.ontransitionend = () => {
                if(position == this.params.positionMax) {
                    this.el.animator.classList.add('no-transition-first');
                    this.params.cloneItem.classList.add('loaded');
                    this.params.cloneItem.style.left = this.params.slideWidth + 'px';
                    this.el.animator.style.transform = 'translateX(' + this.params.slideWidth * -1 + 'px)';
                    this.params.position = 1;
                } else if(position == 0) {
                    this.el.animator.classList.add('no-transition-first');
                    //this.params.cloneItemLast.classList.add('loaded');
                    this.params.cloneItemLast.style.left = (this.params.positionMax - 1) * this.params.slideWidth + 'px';
                    this.el.animator.style.transform = 'translateX(' + (this.params.positionMax - 1) * this.params.slideWidth * -1 + 'px)';
                    this.params.position = this.params.slideTotal - 2;
                }

                this.el.items.classList.add('t-end');
            }
        }

        setPosition(position) {

            if(this.el.slider.getAttribute(this.params.dataInfiniteSlider) === 'true') {
                this.setPositionInfinity(position);
                this.params.hasMoved = true;

                // Take care of scroll indicator
                if(typeof this.el.scrollIndicator !== 'undefined') {

                    if(position === 0) {
                        this.el.scrollIndicator.style.left = (this.el.slidesBeforeClonesTotal - 1) * (100 / this.el.slidesBeforeClonesTotal) + '%';
                    } else if( (position === 1) || (this.params.positionMax === position) ) {
                        this.el.scrollIndicator.style.left = '0%';
                    } else if(position < this.params.positionMax) {
                        this.el.scrollIndicator.style.left = (position - 1) * (100 / this.el.slidesBeforeClonesTotal) + '%';
                    }
                }
            } else {
                if (position > this.params.positionMax) {
                    this.params.position = this.params.positionMax;
                } else {
                    this.params.position = (position < 0) ? 0 : position;
                }

                const xTarget = this.params.position * this.params.slideWidth * -1;
                this.el.animator.style.transform = 'translateX(' + xTarget + 'px)';
                this.params.hasMoved = true;

                // Take care of buttons
                if (position == 0) {
                    this.el.buttonPrev.setAttribute('disabled', 'disabled');
                } else {
                    this.el.buttonPrev.removeAttribute('disabled');
                }
                if (position >= this.params.positionMax) {
                    this.el.buttonNext.setAttribute('disabled', 'disabled');
                } else {
                    this.el.buttonNext.removeAttribute('disabled');
                }

                // Take care of scroll indicator
                if(typeof this.el.scrollIndicator !== 'undefined') {
                    this.el.scrollIndicator.style.left = position * (100 / (this.params.positionMax + 1)) + '%';
                }

                this.el.animator.ontransitionend = () => {
                    this.el.items.classList.add('t-end');
                }
            }

            // take care of slider header
            if(this.params.hasHeader) {
                this.getVisibleSlides();
                this.setSliderHeaderHandleActiveItems();
            }

            this.dispatchEvent('SesoSlider.positionChanged', {
                bubbles: false,
                cancelable: false,
                detail: {
                    posX: this.params.posX
                }
            });
        }

        autoplaySlides() {
            let autoplayStatus = this.el.slider.getAttribute(this.params.dataAutoplay);
            let autoplayDelay = this.el.slider.getAttribute(this.params.dataDelay);
            let autoplayInterval = null;

            if(autoplayStatus == 'true') {
                let btnNext = this.el.buttonNext;

                autoplayInterval = setInterval(function(){
                    if(document.hasFocus()){
                        btnNext.click();
                    }
                }, autoplayDelay);

                this.el.slider.addEventListener("mouseover", function () {
                    clearInterval(autoplayInterval);
                    autoplayInterval = null;
                });

                this.el.slider.addEventListener("touchstart", function () {
                    clearInterval(autoplayInterval);
                    autoplayInterval = null;
                });

                this.el.slider.addEventListener("mouseleave", function () {
                    autoplayInterval = setInterval(function(){
                        if(document.hasFocus()){
                            btnNext.click();
                        }
                    },autoplayDelay);
                });

                this.el.slider.addEventListener("touchend", function () {
                    autoplayInterval = setInterval(function(){
                        if(document.hasFocus()){
                            btnNext.click();
                        }
                    },autoplayDelay);
                });
            }
        }

        resize() {
            this.params.sliderVisibleWidth = this.el.slider.getBoundingClientRect().width;
            this.params.slideWidth = this.el.slides.item(0).getBoundingClientRect().width >= 1540 ? 1540 : this.el.slides.item(0).getBoundingClientRect().width;
            this.params.slideTotal = this.el.slides.length;
            this.params.slideVisibleTotal = Math.floor(parseInt(this.el.animator.getBoundingClientRect().width + 15) / this.params.slideWidth);
            this.params.positionMax = this.params.slideTotal - this.params.slideVisibleTotal;
            this.params.trackingEndX = this.params.slideWidth * this.params.slideTotal;

            let sliderNeeded = this.params.sliderVisibleWidth >= (this.params.slideWidth * this.params.slideTotal) ? true : false;

            // check if slider is needed
            if (sliderNeeded && this.params.isInitialized) {
                // if all slides are visible and slider was created -> destroy slider
                this.destroy();
            } else if (!sliderNeeded && !this.params.isInitialized) {
                // if not all slides are visible and slider is not created -> create
                // check if initial slide is set, if not -> set to first
                var initialSlide = this.el.slider.getAttribute(this.params.dataInitialSlide);
                if(this.params.initialSlideSet === false && typeof initialSlide !== 'undefined') {
                    if(this.el.slider.getAttribute(this.params.dataInfiniteSlider) === 'true'){
                        this.params.position = (parseInt(initialSlide)) >= 0 ? (parseInt(initialSlide)) : 0;
                    } else {
                        this.params.position = (parseInt(initialSlide - 1)) >= 0 ? (parseInt(initialSlide - 1)) : 0;
                    }
                    this.params.initialSlideSet = true;
                }
                this.create();
                this.params.initialSlideSet = true;
            } else if (this.params.isInitialized) {
                // if not all slides are visible and slider is created -> reposition
                this.setPosition(this.params.position, 'resize');
            }

            // get visible slides
            this.getVisibleSlides();

            // if there is a slider header
            if(this.params.hasHeader) {
                this.resizeSliderHeader();
            }

            // if there are expanders - set correct content height
            if(this.params.hasExpandables) {
                this.detectExpanderHeights();
            }

            // Set scroll indicator width
            if(typeof this.el.scrollIndicator !== 'undefined') {
                if(this.el.slider.getAttribute(this.params.dataInfiniteSlider) === 'true') {
                    this.el.scrollIndicator.style.width = 100 / (this.params.positionMax - 1) + '%';
                } else {
                    this.el.scrollIndicator.style.width = 100 / (this.params.positionMax + 1) + '%';
                }
            }

            this.dispatchEvent('SesoSlider.resized', {
                bubbles: false,
                cancelable: false,
                detail: {
                    slideWidth: this.params.slideWidth,
                    slideTotal: this.params.slideTotal,
                }
            });

            this.positionScrollIndicator();
        }

        create() {
            // show controls
            this.el.container.classList.remove(this.params.noSliderClass);

            // set slider position
            this.setPosition(this.params.position, 'resize');

            // initialze
            this.params.isInitialized = true;
            this.el.slider.classList.add(this.params.sliderInitializedClass);
            this.dispatchEvent('SesoSlider.created');
        }

        destroy() {
            // hide controls
            this.el.container.classList.add(this.params.noSliderClass);

            // reset slider position
            this.el.animator.style.transform = 'translateX(0)';

            // destroy
            this.params.isInitialized = false;
            this.el.slider.classList.remove(this.params.sliderInitializedClass);
            this.dispatchEvent('SesoSlider.destroyed');
        }

        getVisibleSlides() {
            var cutoff = parseFloat(this.el.animator.style.transform.replace(/[a-z()-]/gi, ''));
            var firstVisibleSlide = '';

            // get first visible slide
            for (let i = 0; i < this.el.slides.length; i++) {
                if (this.el.slides[i].offsetLeft >= cutoff) {
                    firstVisibleSlide = parseInt(this.el.slides[i].getAttribute('data-slide'));
                    break;
                }
            };

            // get amount of visible elements
            var containerWidth = this.el.slider.getBoundingClientRect().width;
            var slideWidth = this.el.slides[0].getBoundingClientRect().width;
            var visibleSlideCount = Math.floor(containerWidth / slideWidth);


            // get visible slide ids
            var slideIds = [];
            for (let j = 0; j < visibleSlideCount; j++) {
                slideIds.push(firstVisibleSlide + j);
            }

            // set visible slide ids
            this.params.visibleSlideIds = slideIds;

            if(this.params.hasHeader) {
                // get dimensions of visible area
                var cutoffHeader = parseFloat(this.el.sliderHeaderAnimator.style.transform.replace(/[a-z()-]/gi, ''));
                var itemPadding = parseFloat(window.getComputedStyle(this.el.sliderHeaderWrapper, null).getPropertyValue('padding-left'));
                cutoffHeader = !isNaN(cutoffHeader) ? (cutoffHeader + itemPadding) : itemPadding;
                var headerWidth = this.el.sliderHeaderAnimator.getBoundingClientRect().width;

                // get position of active element
                var actItemPos = this.el.sliderHeaderItems[this.params.position].getBoundingClientRect().left;

                // check if active element is visible
                if(actItemPos < cutoffHeader) {
                    // not visible -> scroll to left
                    this.el.sliderHeaderBtnPrev.click();
                } else if (actItemPos > headerWidth) {
                    // not visible -> scroll to right
                    this.el.sliderHeaderBtnNext.click();
                }
            }
        }

        initSliderHeader() {
            // set numberations to slider header items
            for (let i = 0; i < this.el.sliderHeaderItems.length; i++) {
                this.el.sliderHeaderItems[i].setAttribute(this.params.dataSlideNumber, i + 1);
            }

            this.sliderHeaderHandleEvents();

            // destroy slider header initial
            this.destroySliderHeader();
        }

        sliderHeaderHandleEvents() {
            let that = this;

            var sliderNav = this.el.sliderHeaderWrapper.querySelector('nav');

            // scrolling
            this.el.sliderHeaderAnimator.addEventListener('scroll', function() {

                if (!that.params.ticking) {
                    window.requestAnimationFrame(function() {
                        var headerSliderOverflow = that.detectSliderHeaderOverflow();
                        that.el.sliderHeaderWrapper.setAttribute('data-overflowing', headerSliderOverflow);
                        that.toggleSliderHeaderControls(headerSliderOverflow);
                        that.params.ticking = false;
                    });
                }
                that.params.ticking = true;
            });

            // slider header control next
            this.el.sliderHeaderBtnNext.addEventListener('click', function(event) {
                // if slider is already moving - return
                if(that.params.sliderHeaderIsMoving === true) {
                    event.preventDefault();
                }

                var sliderOverflow = that.detectSliderHeaderOverflow();
                // if there is space to scroll on the right side
                if (sliderOverflow === 'right' || sliderOverflow === 'both') {
                    // get the right edge
                    var navBarRightEdge = sliderNav.getBoundingClientRect().right;
                    var navBarScrollerRightEdge = that.el.sliderHeaderAnimator.getBoundingClientRect().right;
                    // detect how much space is available to scroll
                    var availableScrollRight = Math.floor(navBarRightEdge - navBarScrollerRightEdge);

                    // make transition available (set in CSS) when moving
                    sliderNav.classList.remove(that.params.classNoTransition);

                    // if the space thats available is less than our defined distance -> move to the right end
                    // otherwise, move defined distance
                    if (availableScrollRight < that.params.sliderHeaderMoveDistance) {
                        sliderNav.style.transform = 'translateX(-' + (availableScrollRight) + 'px)';
                    } else {
                        sliderNav.style.transform = 'translateX(-' + (that.params.sliderHeaderMoveDistance) + 'px)';
                    }

                    // update params
                    that.params.sliderHeaderMovingDirection = 'right';
                    that.params.sliderHeaderIsMoving = true;
                }

                // set data overflow
                that.el.sliderHeaderWrapper.setAttribute('data-overflowing', that.detectSliderHeaderOverflow());
            });

            // slider header control prev
            this.el.sliderHeaderBtnPrev.addEventListener('click', function(event) {
                // if slider is already moving - return
                if(that.params.sliderHeaderIsMoving === true) {
                    event.preventDefault();
                }

                var sliderOverflow = that.detectSliderHeaderOverflow();
                // if there is space to scroll on the right side
                if (sliderOverflow === 'left' || sliderOverflow === 'both') {
                    // get how far this panel has been scrolled
                    var availableScrollLeft = that.el.sliderHeaderAnimator.scrollLeft;

                    // make transition available (set in CSS) when moving
                    sliderNav.classList.remove(that.params.classNoTransition);

                    // if the space thats available is less than our defined distance -> move to the left end
                    // otherwise, move defined distance
                    if (availableScrollLeft < that.params.sliderHeaderMoveDistance) {
                        sliderNav.style.transform = 'translateX(' + (availableScrollLeft) + 'px)';
                    } else {
                        sliderNav.style.transform = 'translateX(' + (that.params.sliderHeaderMoveDistance) + 'px)';
                    }

                    // update params
                    that.params.sliderHeaderMovingDirection = 'left';
                    that.params.sliderHeaderIsMoving = true;
                }

                // set data overflow
                that.el.sliderHeaderWrapper.setAttribute('data-overflowing', that.detectSliderHeaderOverflow());
            });

            // transitioning
            sliderNav.addEventListener('transitionend',
                function() {
                    // get the value of the transform, apply that to the current scroll position (so get the scroll pos first)
                    // and remove the transform
                    var styleOfTransform = window.getComputedStyle(sliderNav, null);
                    var tr = styleOfTransform.getPropertyValue('-webkit-transform') || styleOfTransform.getPropertyValue('transform');

                    // if there is no transition -> to default 0
                    var amount = Math.abs(parseInt(tr.split(',')[4]) || 0);
                    sliderNav.style.transform = 'none';
                    sliderNav.classList.add(that.params.classNoTransition);

                    // get the scroll position
                    if (that.params.sliderHeaderMovingDirection === 'left') {
                        that.el.sliderHeaderAnimator.scrollLeft = that.el.sliderHeaderAnimator.scrollLeft - amount;
                    } else {
                        that.el.sliderHeaderAnimator.scrollLeft = that.el.sliderHeaderAnimator.scrollLeft + amount;
                    }
                    that.params.sliderHeaderIsMoving = false;
                },
                false
            );

            // react on slider header item click
            for (var i = 0; i < this.el.sliderHeaderItems.length; i++) {
                this.el.sliderHeaderItems[i].addEventListener('click', function (event) {
                    event.preventDefault();
                    var slidePosition = this.getAttribute(that.params.dataSlideNumber);
                    that.params.position = parseInt(slidePosition) -1;
                    that.setPosition(that.params.position);
                });
            }
        }

        createSliderHeader() {
            var sliderOverflow = this.detectSliderHeaderOverflow();
            this.el.sliderHeaderWrapper.setAttribute('data-overflowing', sliderOverflow);
            this.toggleSliderHeaderControls(sliderOverflow);

            // remove no-slider class
            this.el.sliderHeaderWrapper.classList.remove(this.params.noSliderClass);

            // set initialized value
            this.el.sliderHeaderWrapper.setAttribute('data-is-initialized', 'true');
        }

        updateSliderHeader() {
            var sliderOverflow = this.detectSliderHeaderOverflow();
            this.el.sliderHeaderWrapper.setAttribute('data-overflowing', sliderOverflow);
            this.toggleSliderHeaderControls(sliderOverflow);
        }

        destroySliderHeader() {
            var sliderOverflow = this.detectSliderHeaderOverflow();
            this.el.sliderHeaderWrapper.setAttribute('data-overflowing', sliderOverflow);
            this.toggleSliderHeaderControls(sliderOverflow);

            // add no-slider class
            this.el.sliderHeaderWrapper.classList.add(this.params.noSliderClass);

            // set initialized value
            this.el.sliderHeaderWrapper.setAttribute('data-is-initialized', 'false');
        }

        setSliderHeaderHandleActiveItems() {
            // get active items
            var activeItemIds = this.params.visibleSlideIds;
            let that = this;
            var indicatorWidth = 0;
            var firstActive = undefined;

            // unset inactive items
            for(var k = 0; k < this.el.sliderHeaderItems.length; k++) {
                for (var l = 0; l < activeItemIds.length; l++) {
                    if(parseInt(this.el.sliderHeaderItems[k].getAttribute(this.params.dataSlideNumber)) !== activeItemIds[l]) {
                        this.el.sliderHeaderItems[k].classList.remove(this.params.classActive);
                    }
                }
            }

            // set active items
            for (var i = 0; i < activeItemIds.length; i++) {
                for(var j = 0; j < this.el.sliderHeaderItems.length; j++) {
                    if(parseInt(this.el.sliderHeaderItems[j].getAttribute(this.params.dataSlideNumber)) === activeItemIds[i]) {
                        this.el.sliderHeaderItems[j].classList.add(this.params.classActive);
                    }
                }
            }

            // set indicator
            setTimeout(function() {
                for (var m = 0; m < that.el.sliderHeaderItems.length; m++) {
                    if(that.el.sliderHeaderItems[m].classList.contains(that.params.classActive)) {
                        indicatorWidth += parseFloat(that.el.sliderHeaderItems[m].getBoundingClientRect().width);
                    }
                }

                var itemPadding = parseFloat(window.getComputedStyle(that.el.sliderHeaderItems[0].closest('li'), null).getPropertyValue('padding-right')) *2;
                itemPadding = (itemPadding * activeItemIds.length) - itemPadding;
                indicatorWidth = indicatorWidth + itemPadding;

                firstActive = that.el.sliderHeaderItems[parseInt(activeItemIds[0]) - 1];

                if(typeof firstActive !== 'undefined') {
                    var firstActivePosition = firstActive.getBoundingClientRect().left;
                    var headerContainerPosition = that.el.sliderHeaderWrapper.querySelector('nav').getBoundingClientRect().left;
                    var distance = firstActivePosition - headerContainerPosition;
                    var scroll = that.el.sliderHeaderWrapper.querySelector('nav').scrollLeft;
                    that.el.sliderHeaderIndicator.classList.add('active');
                    that.el.sliderHeaderIndicator.style.transform = 'translateX(' + (distance + scroll) + 'px) scaleX(' + (indicatorWidth * 0.01) +')';
                }

            }, that.params.sliderHeaderAnimationDuration);

        }

        detectSliderHeaderOverflow() {
            var overflowDirection;
            var sliderPadding = parseFloat(window.getComputedStyle(this.el.sliderHeaderWrapper, null).getPropertyValue('padding-left'));
            var containerDimensions = this.el.sliderHeaderWrapper.getBoundingClientRect();
            var containerDimensionsRight = Math.floor(containerDimensions.right) - sliderPadding;
            var containerDimensionsLeft = Math.floor(containerDimensions.left) + sliderPadding;
            var tabsDimensions = this.el.sliderHeaderAnimator.querySelector('ul').getBoundingClientRect();
            var tabsDimensionsRight = Math.floor(tabsDimensions.right);
            var tabsDimensionsLeft = Math.floor(tabsDimensions.left);

            if (containerDimensionsLeft > tabsDimensionsLeft && containerDimensionsRight < tabsDimensionsRight) {
                overflowDirection = 'both';
            } else if (tabsDimensionsLeft < containerDimensionsLeft) {
                overflowDirection = 'left';
            } else if (tabsDimensionsRight > containerDimensionsRight) {
                overflowDirection = 'right';
            } else {
                overflowDirection = 'none';
            }

            return overflowDirection;
        }

        toggleSliderHeaderControls(sliderHeaderOverflow) {
            if (sliderHeaderOverflow === 'both') {
                // both controls
                this.el.sliderHeaderBtnPrev.disabled = false;
                this.el.sliderHeaderBtnNext.disabled = false;
            } else if (sliderHeaderOverflow === 'left') {
                // only left control
                this.el.sliderHeaderBtnPrev.disabled = false;
                this.el.sliderHeaderBtnNext.disabled = true;
            } else if(sliderHeaderOverflow === 'right') {
                // only right control
                this.el.sliderHeaderBtnPrev.disabled = true;
                this.el.sliderHeaderBtnNext.disabled = false;
            } else {
                // no controls
                this.el.sliderHeaderBtnPrev.disabled = true;
                this.el.sliderHeaderBtnNext.disabled = true;
            }
        }

        resizeSliderHeader() {
            // manage active items
            this.setSliderHeaderHandleActiveItems();

            // manage slider header
            var padding = parseFloat(window.getComputedStyle(this.el.sliderHeaderWrapper, null).getPropertyValue('padding-left')) * 2;
            var visibleWidth = this.el.sliderHeaderWrapper.getBoundingClientRect().width - padding;
            var totalWidth = this.el.sliderHeaderAnimator.querySelector('ul').getBoundingClientRect().width;

            var sliderHeaderIsInitialized = this.el.sliderHeaderWrapper.getAttribute('data-is-initialized');
            var sliderNeeded = visibleWidth > totalWidth ? false : true;

            if(sliderNeeded && sliderHeaderIsInitialized === 'true') {
                // slider header is needed and is already initialized -> update slider header
                this.updateSliderHeader();
            } else if(sliderNeeded && sliderHeaderIsInitialized === 'false') {
                // slider header is needed but not initialized -> init slider header
                this.createSliderHeader();
            } else if(!sliderNeeded && sliderHeaderIsInitialized === 'true') {
                // no slider header needed but initialized -> destroy slider header
                this.destroySliderHeader();
            }
        }

        initExpandables() {
            // init expandables
            let that = this;

            // set expandable ids
            this.params.expandableIds = this.getExpanderIds();

            // react on click
            for (var i = 0; i < this.el.expandablesController.length; i++) {
                this.el.expandablesController[i].addEventListener('click', function () {
                    var dataId = this.closest('.' + that.params.classExpandableItem).getAttribute(that.params.dataAttrExpandableId);
                    if(this.classList.contains(that.params.classExpandableOpen)) {
                        // if expander group is open -> close it
                        that.closeExpanderGroup(dataId);
                    } else {
                        // if expander group is closed -> open it
                        that.openExpanderGroup(dataId);
                    }
                });
            }
        }

        openExpanderGroup(groupId) {
            for (var i = 0; i < this.el.expandablesController.length; i++) {

                if(this.el.expandablesController[i].getAttribute(this.params.dataAttrExpandableId) === groupId) {
                    this.el.expandablesController[i].classList.add(this.params.classExpandableOpen);
                    this.el.expandables[i].classList.add(this.params.classExpandableOpen);
                    this.el.expandablesContent[i].style.height = (this.el.expandablesContent[i].getAttribute(this.params.dataAttrExpandableHeight)) + 'px';
                }
            }
        }

        closeExpanderGroup(groupId) {
            for (var i = 0; i < this.el.expandablesController.length; i++) {
                if(this.el.expandablesController[i].getAttribute(this.params.dataAttrExpandableId) === groupId &&
                    this.el.expandablesController[i].classList.contains(this.params.classExpandableOpen)) {
                    this.el.expandablesController[i].classList.remove(this.params.classExpandableOpen);
                    this.el.expandables[i].classList.remove(this.params.classExpandableOpen);
                    this.el.expandablesContent[i].style.height = '0px';
                }
            }
        }

        detectExpanderHeights() {
            var dataId = '';
            let that = this;
            var heightHelper = [];
            var expanderHeight = 0;

            // for each expander id group
            for (var i = 0; i < this.params.expandableIds.length; i++) {
                dataId = that.params.expandableIds[i];
                // get belonging expandable groups
                heightHelper[dataId] = 0;
                for (var j = 0; j < that.el.expandablesContent.length; j++) {
                    if(that.el.expandablesContent[j].getAttribute(that.params.dataAttrExpandableId) === dataId) {
                        // get heights and take highest for id group
                        expanderHeight = that.el.expandablesContent[j].querySelector('.' + that.params.classExpandableContent).clientHeight;
                        heightHelper[dataId] = (heightHelper[dataId] < expanderHeight) ? expanderHeight
                            : heightHelper[dataId];
                    }
                }
            }

            // set height to expander content
            for (var k = 0; k < that.el.expandablesContent.length; k++) {
                dataId = that.el.expandablesContent[k].getAttribute(that.params.dataAttrExpandableId);
                that.el.expandablesContent[k].setAttribute(that.params.dataAttrExpandableHeight, heightHelper[dataId]);
                // if there are open expanders - update the height
                if(that.el.expandables[k].classList.contains(that.params.classExpandableOpen)) {
                    that.el.expandablesContent[k].style.height = (heightHelper[dataId]) + 'px';
                }
            }
        }

        getExpanderIds () {
            var dataIds = [];
            var id = '';
            for (var i = 0; i < this.el.expandablesContent.length; i++) {
                id= this.el.expandablesContent[i].getAttribute(this.params.dataAttrExpandableId);
                if(dataIds.indexOf(id) === -1) {
                    dataIds.push(id);
                }
            }

            return dataIds;
        }
    }

    if (typeof window !== 'undefined') {
        window.a1FragmentsSlider = SesoSlider;
    }

    window.addEventListener('load', () => {
        // slider tariff mobile
        document.querySelectorAll('a1-fragment[name="slider-fragments-wrapper"]').forEach(function (fragment) {
            window.a1QuotesSlider = new SesoSlider(fragment.querySelector('.slider'));
        });
    });
}
